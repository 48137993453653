.pagina404{
  margin-top: 10rem;
  margin: 10px;

  height: 60rem;
  color: var(--textColor);
  text-align: center;
}

.erru{
	color: var(--mainColor);
	font-size: 6rem;
  background-color: transparent;
  border-color: transparent;
}

.erru:hover{
  background-color: transparent;
  border-color: transparent;
}
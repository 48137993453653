/* opções dos botoes */ 
.options > div > button:last-child {
  border-radius: 15px 15px 15px 0px;
}

.options {
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  */  
  background-color: var(--mainColor2);
  margin-top: 2%;
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: center;
  
}

.options button {
  top: -50px;
  position: relative;
  width: 120px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
}

.options > div:first-child > button:first-child{
  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.options button:hover{
  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.optionsInfo{

  margin-left: 15%; 
  margin-right: 15%; 


  margin-top: 1%;
  text-align: center;
  background-color: var(--mainColor2);  
  
  /*
  border: black;
  border-style:solid; 
  */   
}

.optionsInfo button{
  
  width : 120px;
  height: 120px; 

  margin: 10px;
 
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;

  line-height: 1;
}

.optionsInfo button img{
  
  width : 60px;
  height: 60px; 
 
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
}

@media (max-width: 615px) {
  .optionsInfo button{
  
    width : 85px;
    height: 85px;
    font-size: large;
  }
}


@media (max-width: 960px) {
  .optionsInfo{
  
    margin-left: 0%; 
    margin-right: 0%; 
  }
}

@media (max-width: 497px) {
  .options button {
    width: 100px;
  }
  
  .optionsInfo button{
     width: 100px;
  }
}

@media (max-width: 376px) {
  .options button {
    width: 90px;
  }
  
  .optionsInfo button{
     width: 90px;
  }
}
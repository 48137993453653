
.footer {
  height: 400px;
  color: var(--textColor2);
  display: flex;
  background-color: var(--mainColor2);
}

.footerA{
  width: 35%;
  text-align: center;
  /* 
  border: black;
  border-style:solid;
  */  
  background-color: var(--mainColor2);
  color:  var(--mainColor);
  font-size: large;
  
}


.footerInfoCardA{
  min-height: 100px;
  min-width: 140px;
  max-height: 140px;
  max-width: 220px;

  text-align: center;
  background-color:  var(--textColor2);
  border: 3px var(--mainColor3);
  border-radius: 15px 15px 15px 15px;
  border-style:solid;
  line-height: 1;

  margin-top: 60px;
  margin-left: 5%;

}

.footerInfoCardA p:first-child{
  height: 80px;
  width: 102%;

  margin-top: -5%;
  margin-left: -1%;
  
  background-color:  var(--mainColor);
  border: 3px #b6a6a6;
  border-radius: 15px 15px 0px 0px;
  border-style:solid;
  line-height: 1.2;
  color:  var(--mainColor2);
}

.footerInfoCardA p:last-child{
  margin-top: 2px;
}

.logo2 {
  min-width: 180px;
  max-width: 380px;
  top: 25px;

  margin: auto;
  position: relative;
  border-radius: 20px 20px 20px 0px;

}

.loading{
  max-height: 160px;
}

.logo2 img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.footerInfoCardB{
  min-height: 100px;
  min-width: 140px;
  max-height: 140px;
  max-width: 220px;

  text-align: center;
  
  background-color:  var(--textColor2);
  border: 3px #b6a6a6;
  border-radius: 15px 15px 15px 15px;
  border-style:solid;
  line-height: 1;

  margin-right: 0.5%;  
  margin-top: -100px;
  margin-left: 55%;

  text-decoration: none;

}


.footerInfoCardB p:first-child{
  height: 80px;
  width: 102%;

  margin-top: -5%;
  margin-left: -1%;
  
  background-color:  var(--mainColor);
  border: 3px #b6a6a6;
  border-radius: 15px 15px 0px 0px;
  border-style:solid;
  line-height: 1.2;
  color:  var(--mainColor2);
}

.footerInfoCardB p:last-child{
  margin-top: 2px;
}


.footerB {
  width: 65%;
  position: relative;
  background-color:  var(--mainColor);
  border-radius: 250px 0px 0px 250px;
  
  display: flex;
   /* 
  border: black;
  border-style:solid;
  overflow-x: auto;
  */  
  justify-content: space-between;
}

.footerRedes {
  max-width: 400px;
  min-width: 165px;

  width: 50%;
  height: 43%;
  top: 25%;

  position: relative;
  background-color: transparent;
  text-align: center;
  line-height: 1.2;

   /* 
  border: black;
  border-style:solid;
  */  
}



.redes {

  min-width: 160px;

  background-color: transparent;
  line-height: 0;


   /* 
   
  overflow-x: auto;
  border: black;
  border-style:solid;
  */  
  
}

.redes button{
  cursor: pointer;
  width: 21%;
  min-width: 68px;

  background-color: transparent;
  text-align: center;
  line-height: 0;
  border: none transparent;
}


.redes img{
  width: 90%;
  object-fit: cover;
   /* 
  border: black;
  border-style:solid;
  */  
  
}

.footerLinks {
  float: right;
  max-width: 500px;
  width: 25%;
  height: 60%;
  top: 20%;
  min-width: 100px;
  display: flex;
  position: relative;
  background-color: transparent;
  text-align: center;
  
}

.logo3 {
  float: right;
  z-index: 1;

  max-width: 140px;
  min-width: 100px;

  right: -183%;
  top: -10px;

  display: flex;
  position: relative;
  background-color: transparent;


}

.logo3 img{
  width: 100%;
  object-fit: cover;
}


/* TEXTO REDES */ 

@media (max-width: 900px) {
  .footerRedes > p:nth-of-type(1){
    color: transparent;
  }   
}

@media (min-width: 901px) {
  .footerRedes > p:nth-of-type(2){
    color: transparent;
  }   
}

/*  CONTAINER FOOTER  */ 


@media (max-width: 1320px) {
  .footerInfoCardB{
    margin-top: 20px;
    margin-left: 8vw;
  }

  .footerInfoCardA{
    margin-top: -3vh;
    margin-left: 8vw;
  } 
  .logo3{
    top: -12%;
  }
  .footerLinks {
    display: block;
  }
}



@media (max-width: 1150px) {
  .footerRedes {
    top: 11vh;
  }
  .redes {
    height: 145px;
  }
}


@media (max-width: 1020px) {
  .footerInfoCardA{
    margin: auto;
    margin-top: -2vw;
    font-size: medium;
  } 
  
  .footerInfoCardB{
    margin: auto;
    margin-top: 2vw;
    font-size: medium;
  }  
  .logo3{
    top: -10%;
  }
}


/*  FOOTER MOBILE  */ 
@media (max-width: 900px) {

  .logo3{    
    top: 33rem;
    left: -10px;
  }

  .footer {
    height: 60rem;
    display: block;
  }

  /* 
    border: black;
    border-style:solid;
    */  

  .footerA{
    width: 100%;
    height: 27rem;
    text-align: center; 
  }

  .footerB {
    width: 100%;
    height: 34rem;

    position: relative;
    display: block;

    border-radius: 0px 250px 0px 0px;
  }


  .footerRedes {

    height: 18%;

    min-width: 300px;
    max-width: 300px;
    margin: auto;
    line-height: 0.8;    
    overflow-x: auto;

    top: 3.7rem;
    position: relative;

    margin-top: 10px;

  
  }

  
  .footerLinks {
    width: 50%;
    height: 44%;    
    top: 8rem;

    
  }

}
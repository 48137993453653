
.noticiaEspecifica{
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  background-color: var(--mainColor2);
  */  
  
  margin-top: 10%;
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;
  
  /*   bloquear ficar página rolando */
  overflow: hidden;
  margin-bottom: 5%;

}


.titulo{
  margin-left: 10vw;
  margin-right: 35vw;
  margin-bottom: -7vw;
  margin-top: -1vw;
  color: var(--mainColor);
  font-size: 40px;
}  

.selecionada{

  max-width: 310px;
  width: 33vh;
  height: 50px; 
     
  position: relative;

  left: 65vw;
  top: 8rem;

  text-align: center;   
  font-size: 22px;
  
  background-color: var(--mainColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}

.maisNoticias{

  max-width: 310px;
  width: 33vh;
  height: 50px; 
     
  position: relative;

  left: 64vw;
  top: -1vw;

  text-align: center;   
  font-size: 22px;
  
  background-color: var(--mainColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}

  /*  AJUSTE 2 NOTICIAS DIREITA */ 
.noticiaEspecifica > div > div:last-child{
  height: 73vw; 
  width : 43vw;
  overflow: hidden;
  margin-top: -15px;
}


@media (min-width: 1620px) {
  .noticiaEspecifica > div > div:last-child{
    height: 120vh; 
    width : 500px;
  }
  .maisNoticias{

    max-width: 310px;
    width: 33vh;
    height: 50px; 
       
    position: relative;
    left: 65vw;
    top: -20px;
  }
}


.noticiaEspecificaInfo {

  margin-left: 10%; 
  margin-right: 10%; 
  margin-top: 1%;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
  
  
}

.textoNoticiaEspecifica{
  font-size: 22px;
  text-align: justify;
  
  max-width : 1000px;
  min-width : 340px;
    
  line-height: 1.6;
  color: var(--mainColor);
  margin-top: 2rem;
  
}  

.noticiasInfoEspecifica{
  font-size: 22px;
  text-align: justify;
  
  max-width : 1000px;
  min-width : 340px;
    
  line-height: 1;
  color: var(--mainColor);
  
  margin-top: 12px;
  
}  

.noticiaEspecificaInfo > div:last-child  button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 400px;
  max-height: 350px; 
  min-width : 340px;
  min-height: 150px;

  margin: 16px;
    
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
  
}

/* retirado por estar subindo em cima dos outros botoes na página noticias*/  
/* 
.noticiaEspecificaInfo > div:last-child  button:hover{
  transform: scale(1.08);
  transition: 1s;
  cursor: pointer;
}
*/  


.noticiaEspecificaInfo div:last-child p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:left;

  white-space: break-spaces;

  height: 86px;
  width: 95%;

  top: -14vh;
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;

  margin-left: 0vw;  
}

.noticiaEspecificaInfo div:last-child p:last-child{
  margin-top: 9px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 52%;
}

.noticiaEspecificaInfo button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}

@media (max-width: 1290px) {
  .noticiaEspecificaInfo {  
    flex-direction: column;
  }
  .noticiaEspecifica > div > div:last-child{
    height: auto; 
    width : auto;
    overflow: hidden;
  }
  .titulo{
    margin-left: 14vw;
    margin-right: 10vw;
    margin-top: 5vw;
    line-height: 1.2;
  }  

  .selecionada{
    max-width: 80%;
    width: 102vh;
    left: 10vw;
    top: 1vw;   
  }

  .maisNoticias{
    max-width: 80%;
    width: 102vh;
    left: 10vw;
    top: 0vw;   
  }

  
}


@media (max-width: 499px) {

  .titulo{
    font-size: 32px;
    margin-left: 14vw;
    margin-right: 10vw;
    margin-top: 5vw;
    line-height: 1.2;
  }  

  .noticiasInfoEspecifica{
    font-size: 20px;  
  }
 
  .textoNoticiaEspecifica {
    font-size: 20px;
    min-width : 300px;
  }

  .noticiaEspecificaInfo > div:last-child  button{
    min-width : 280px;
  }

  .noticiaEspecificaInfo div:last-child p {
    line-height: 1;
    font-size: 20px;
    height: 79px; 
  }

  .noticiaEspecificaInfo div:last-child p:last-child{
    height: 30px;
  }
}

@media (max-width: 399px) {
  .noticiaEspecificaInfo div:last-child p {  
    height: 56px; 
  }
  .noticiaEspecificaInfo div:last-child p:last-child{
    margin-top: 29px;
    height: 27px;
  }
}

@media (max-width: 376px) {
  .noticiaEspecificaInfo div:last-child p {  
    height: 56px; 
  }
  .noticiaEspecificaInfo div:last-child p:last-child{
    margin-top: 3px;
    height: 27px;
  }
}






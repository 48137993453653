@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');

:root{
    --background: #EEEFF1;
    --menu-lateral: rgba(255, 255, 255, 0.2);
    --colorFont: #232323;
    --fontText: 'Inter';
    --fontTitle: 'Reem Kufi Regular'
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
body{
    width: 100%;
    background-color: var(--background);
}
.container-municipio{
    display: flex;
    justify-content: center;
    gap: 5rem;
    margin-top: 3rem;
    flex-wrap: wrap;
}

.municipio{
    display: flex;
    flex-direction: column;
}
.menu-municipio{
    width: 20%;
    height: 40%;
}
.container-prefeita{  
    background: var(--menu-lateral);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    border-radius: 10px;

}

.menu-municipio img{
    width: 100%;
    padding: 15px 15px;
    border-radius: 20px;
}

.menuItem{
    margin-top: 1rem;
    background: var(--menu-lateral);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    border-radius: 10px;
}


.menuItemInfo{
    margin-top: 1rem;
    background: var(--menu-lateral);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    border-radius: 10px;
   
}
.menuItemInfo li{
    font-family: var(--fontText);
    font-size: 1.2rem;
    font-weight: 400;
}

.menuItem li:hover{
    
    cursor: pointer;
    color: #004348;
}

.bg-text h2,h3{
    text-align: center;
    font-size: 1.5rem;
    font-family: var(--fontTitle);
}
.container{
    max-width: 1036px;
    min-width: 100px;
}
.container img{
    width: 1036px;
    margin-top: 2rem;
    border-radius: 36px;
}
.container p{
    font-family: var(--fontTitle);
    text-align: justify;
}
.sobre{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;       
}

.sobre p{
    font-family: var(--fontText);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
}

.historia {
    margin-top: 2rem;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
}

.historia p{
    font-family: var(--fontText);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 2rem;
}
.hino{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; 
    gap: 2rem;
}
.hino p{
    font-family: var(--fontText);
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
    margin-bottom: 2rem;
}

.text-hino{
    display: flex;
    gap: 3rem;
}

.simbolos ::-webkit-scrollbar-thumb{
    padding: 2rem 0;
    width: 100%;
}


.simbolo-img img{
    width: 3rem;
    height: 15rem;
}

.cardImg{
    width: 1100px;
    display: flex;
    gap: 5rem;
    align-items: center;
    overflow-x: auto;
}


.cardImg img{
    width: auto;
    height: 13rem;
   
}
 /* Pontos tiristicos */
.pontosTuristicos{
    margin: 4rem 0;
    display: flex;  
    flex-direction: column;
    align-items: center;
   gap: 2rem;
}
.mapouter{
    display: flex;
    justify-content: space-between;
    gap: 10rem;
}

.mapouter h2{
    margin-top: 3rem;
    width: 17rem;
    font-family: var(--fontTitle);
}
.mapouter button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border: none;
    background-color: none;

    
    background-color: #5799D9;  
    width: 16rem;
    padding: 10px;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
    font-size: 1.1rem;
    color: white;
    margin-top: 1rem;
}

.mapouter span{
    font-family: 'Inter';
    font-weight: 400;
    color: white;
    font-size: 1.4rem;
    text-transform: uppercase;
}


.gmap_canvas iframe{
    height: 20rem;
    width: 20rem;
    border-radius: 50%;
    border: 1px solid black;
}

.link_top{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    background-color: #57C9AE;
    border-radius: 50%;
    cursor: pointer;
    position: fixed;
    right: 1rem;
    bottom: 8%;
    z-index: 99999999999;

}
.link_top svg{
    font-size: 30px;
    color: white;
}

.historia .carrousel img{
    border-radius: 10px;
}
  
  
@media( width < 322px){
    .municipio{
        display: flex;
        
       
     }
     .sobre .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 18rem;     
       
     }
     .sobre .container p{
        font-size: 1rem;
       
       
     }
     .sobre .container img{ 
         width: 18rem;
     }
 
     .historia .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
     }
     .historia .container p{
         font-size: 1.2rem;
         width: 18rem;
         
      }
      .historia .carrousel{
        width: 19rem;
        margin: 1rem 0;
        
      }
      
      .hino .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .text-hino {
         flex-direction: column;
         font-size: 1.2rem;
         width: 18rem;
      }
 
      .simbolos .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .cardImg{
         width: 300px;
         display: flex;
         gap: 5rem;
         align-items: center;
         overflow-x: auto;
     }
 
     
      
      .pontosTuristicos .mapouter {
         flex-direction: column-reverse;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         gap: 0;
         width: 100%;
         margin-top: 4rem;
      }
 
      .mapouter h2{
         width: 20rem;
         margin-top: 1rem;
         text-align: center;
      }
 
      .mapouter button{
         margin-left: 2rem;
      }
 
      .menu-municipio{
         width: 18rem;
         margin: 1rem 0rem;
      }
 
      .container-municipio{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column-reverse;
         margin-top: 0;
      }
}
  
@media (width > 321px) and (width < 481px) {
    .municipio{
       display: flex;
      
    }
    .sobre .container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;      
        display: flex;
        width: 100%;
       
    }
    .sobre .container p{
       font-size: 1.2rem;
       width: 22rem;
      
       
    }
    .sobre .container img{ 
        width: 22rem;
    }

    .historia .container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;      
        display: flex;
        width: 100%;
    }
    .historia .container p{
        font-size: 1.2rem;
        width: 22rem;
        
     }
     .historia .carrousel{
       width: 20rem;
     }
     
     .hino .container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;      
        display: flex;
        width: 100%;
     }

     .text-hino {
        flex-direction: column;
        font-size: 1.2rem;
        width: 22rem;
     }

     .simbolos .container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;      
        display: flex;
        width: 100%;
     }

     .cardImg{
        width: 350px;
        display: flex;
        gap: 5rem;
        align-items: center;
        overflow-x: auto;
    }

    
     
     .pontosTuristicos .mapouter {
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;      
        display: flex;
        gap: 0;
        width: 100%;
     }

     .mapouter h2{
        width: 22rem;
        text-align: center;
     }

     .mapouter button{
        margin-left: 3.2rem;
     }

     .menu-municipio{
        width: 22rem;
        margin: 2rem 0;
     }

     .container-municipio{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
     }
    
}

@media (width > 481px) and (width < 769px) {
  
    .municipio{
        display: flex;
        width: 100vw;  
     }
     .sobre .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
         
     }
     .sobre .container p{
        font-size: 1.2rem;
        margin-left: 2rem;
        width: 40rem;
       
        
     }
     .sobre .container img{ 
         width: 40rem;
         margin-left: 2rem;
     }
 
     .historia .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
     }
     .historia .container p{
         font-size: 1.2rem;
         margin-left: 2rem;
         width: 40rem;
         
      }
      .historia .carrousel{
        width: 40rem;
        margin-left: 2rem;
      }
      
      .hino .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .text-hino {
         flex-direction: column;
         font-size: 1.2rem;
         width: 40rem;
         margin-left: 2rem;
      }
 
      .simbolos .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .cardImg{
         width: 450px;
         display: flex;
         gap: 5rem;
         align-items: center;
         overflow-x: auto;
     }
 
     
      
      .pontosTuristicos .mapouter {
        margin-top: 4rem;
         flex-direction: column-reverse;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         gap: 0;
         width: 100%;
      }
 
      .mapouter h2{
         width: 22rem;
         text-align: center;
      }
 
      .mapouter button{
         margin-left: 3.2rem;
      }
 
      .menu-municipio{
         width: 30rem;
         margin: 2rem 0;
      }
 
      .container-municipio{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column-reverse;
      }

      .link_top{
        display: flex;
        justify-content: center;
        align-items: center;
    
        width: 60px;
        height: 60px;
        background-color: #57C9AE;
        border-radius: 50%;
        cursor: pointer;
        position: fixed;
        right: 2rem;
        bottom: 8%;
        z-index: 99999999999;
    
    }
}

@media (width > 769px) and (width < 993px) {
    .municipio{
        display: flex;
        width: 100vw;  
     }
     .sobre .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
         
     }
     .sobre .container p{
        font-size: 1.2rem;   
        width: 50rem;
       
        
     }
     .sobre .container img{ 
         width: 50rem;  
     }
 
     .historia .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
     }
     .historia .container p{
         font-size: 1.2rem;
         width: 50rem;
         
      }
      .historia .carrousel{
        width: 50rem;      
      }
      
      .hino .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .text-hino {
         flex-direction: column;
         font-size: 1.2rem;
         width: 50rem;
      }
 
      .simbolos .container{
        margin-top: 2rem;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .cardImg{
         width: 740px;
         display: flex;
         gap: 5rem;
         align-items: center;
         overflow-x: auto;
     }
 
     
      
      .pontosTuristicos .mapouter {
        margin-top: 4rem;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         gap: 7rem;
         width: 100%;
      }
 
      .mapouter h2{
         width: 22rem;
         text-align: center;
      }
 
      .mapouter button{
         margin-left: 3.2rem;
      }
 
      .menu-municipio{
         width: 30rem;
         margin: 2rem 0;
      }
 
      .container-municipio{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column-reverse;
      }

      .link_top{
        display: flex;
        justify-content: center;
        align-items: center;
    
        width: 60px;
        height: 60px;
        background-color: #57C9AE;
        border-radius: 50%;
        cursor: pointer;
        position: fixed;
        right: 2rem;
        bottom: 8%;
        z-index: 99999999999;
    
    }

}

@media (width > 993px) and (width < 1200px) {
    .municipio{
        display: flex;
        width: 100vw;  
     }
     .sobre .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
         
     }
     .sobre .container p{
        font-size: 1.2rem;   
        width: 50rem;
       
        
     }
     .sobre .container img{ 
         width: 50rem;  
     }
 
     .historia .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
     }
     .historia .container p{
         font-size: 1.2rem;
         width: 50rem;
         
      }
      .historia .carrousel{
        width: 50rem;      
      }
      
      .hino .container{
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .text-hino {
         flex-direction: column;
         font-size: 1.2rem;
         width: 50rem;
      }
 
      .simbolos .container{
        margin-top: 2rem;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         width: 100%;
      }
 
      .cardImg{
         width: 740px;
         display: flex;
         gap: 5rem;
         align-items: center;
         overflow-x: auto;
     }
 
     
      
      .pontosTuristicos .mapouter {
        margin-top: 4rem;
         justify-content: flex-start;
         align-items: center;      
         display: flex;
         gap: 7rem;
         width: 100%;
      }
 
      .mapouter h2{
         width: 22rem;
         text-align: center;
      }
 
      .mapouter button{
         margin-left: 3.2rem;
      }
 
      .menu-municipio{
         width: 30rem;
         margin: 2rem 0;
      }
 
      .container-municipio{
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column-reverse;
      }

      .link_top{
        display: flex;
        justify-content: center;
        align-items: center;
    
        width: 60px;
        height: 60px;
        background-color: #57C9AE;
        border-radius: 50%;
        cursor: pointer;
        position: fixed;
        right: 2rem;
        bottom: 8%;
        z-index: 99999999999;
    
    }
}
    
/* opções dos botoes */ 
.secretarias > div > a:last-child > button {
  border-radius: 0px 15px 15px 0px;
}


.secretarias > div > a:first-child > button {
  border-radius: 15px 0px 0px 15px;
}

.secretarias{
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  background-color: var(--mainColor2);
  */  
  
  margin-top: 5%;
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;

}


.pagNoticias{
  margin-left: 10vw;
  margin-bottom: -3vw;
  margin-top: -1vw;
  color: var(--mainColor);
}  

.secretarias > div:first-child button {
  top: -50px;
  min-width: 170px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
}

.secretarias > div:first-child button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.menuNoticias{
  display:flex;
  margin-left: 10vw;
  text-align: center;
}

.menuNoticias div{
  margin-bottom: 20px;
  text-align: center;
}

.menuNoticias button {
  top: -50px;
  bottom: 50px;
  min-width: 36px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  white-space: nowrap;
  cursor: pointer;
  
}

.menuNoticias div:last-child button{
  min-width: 120px;
}

.menuNoticias button:hover{
  background-color: var(--mainColor);
  border-color: var(--mainColor2);
  color: var(--textColor2);
}


  /*  AJUSTE 2 NOTICIAS DIREITA */ 
.secretarias > div:nth-child(2) > div:nth-child(2){
  height: 630px; 
  width : 52vw;
  overflow-y: hidden;
  overflow-x: hidden;
}

.secretariasInfo {

  margin-left: 10%; 
  margin-right: 10%; 
  margin-top: 1%;
  text-align: center;
  
  /*
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

.secretarias > div:first-child {
  margin-left: 10%;
  margin-right: 14%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}


.secretariasInfo .primeiroBotao{
  
  max-width : 670px;
  max-height: 590px; 
  width : 52vw;
  height: 45.7vw;
  min-width : 340px;
  min-height: 300px; 
  margin-left: 0%; 
  margin-right: 0%; 
}

.secretariasInfo button{
  
  width : 24.8vw;
  height: 21.7vw; 
  max-width : 320px;
  max-height: 280px; 
  min-width : 340px;
  min-height: 150px;

  margin: 16px;
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 15px 15px;

  line-height: 1;
  cursor: pointer;
}

/* retirado por estar subindo em cima dos outros botoes*/  
/*
.secretariasInfo a:hover{
  transform: scale(1.08);
  transition: 2s;
}
*/  


.secretariasInfo  p {
  position: relative;

  left: 10px;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:justify;

  white-space: break-spaces;

  height: 86px;
  width: 95%;

  top: -14vh;
  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

/* Indentificação das secretarias*/  
.secretariasInfo div button  p:last-child {
  margin-top: 10px;
  margin-left: -10px;

  background-color: var(--mainColor);
  border-radius: 0px 15px 0px 15px;

  padding: 6px;
  height: 35px;
  width: 52%;
}

.secretariasInfo button img{
  
  width : 100%;
  height: 100%; 
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
  filter: brightness(0.8);
}

@media (max-width: 1290px) {
  .secretariasInfo {  
    flex-direction: column;
  }
  .secretarias > div:first-child {
    margin-left: 10vw;
    margin-right: 10vw;
  }
  .secretarias > div:nth-child(2) > div:nth-child(2){
    height: auto; 
    width : auto;
    overflow-y: hidden;
    overflow-x: hidden;
  }    
}

@media (max-width: 960px) {
  .menuNoticias{
    display:inline;
    position: relative;
    margin-left: 0vw;
    text-align: center;
    margin-top: -50%;
  }
  .secretariasInfo {
  
    margin-left: 0%; 
    margin-right: 0%; 
  }
}


@media (max-width: 499px) {

  .secretariasInfo  p {  
    height: 76px;
  }
}

@media (max-width: 399px) {
  .secretariasInfo  p {  
    height: 62px;
  }
}


@media (max-width: 376px) {
  .secretariasInfo  p {  
    height: 44px;
  }
}
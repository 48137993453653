
.noticias {
  margin-top: 10vh;
  width: 100%;
  /* 
  width: 98%;
  margin-left: 1%; 
  margin-right: 1%; 
  */  
  background-color: var(--mainColor2);
  /* reduzir espçamento button */  
  font-size: 0;
  text-align: center;
  
}

.videos {
  bottom: 20px;
  position: relative;
  display: none;
  left: 15%;  
}

.videos > iframe {
  width: 53vw;
  height: 30vw;
  
}

.videoSeletor{
  width: 20%;
  height: 30vw;
  position: relative;
  left: 15px;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.videoSeletor iframe{
  width: 100%;
  height: 100%;  
  pointer-events: none;
}

.videoSeletor button{
  width: 100%;
  height: 40%;
  position: relative;
  opacity:100%;
  border: transparent;
}


.noticias li button {
  top: -50px;
  position: relative;
  width: 120px;
  height: 50px; 
  text-align: center;   
  font-size: 22px;
  font-weight: 100;
  background-color: var(--mainColor2);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  border-style: none;
  color: var(--mainColor);
  text-shadow:black;
  cursor: pointer;
}

.noticias li button:first-child {
  width: 200px;
  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}


.noticias li button:last-child {
  width: 200px;
  border-radius: 15px 15px 15px 0px;
}

.noticias li button:hover{
  border-radius: 15px 15px 15px 0px;

  background-color: var(--mainColor);
  border-radius: 15px 15px 0px 0px;
  border-color: var(--mainColor2);
  color: var(--textColor2);
}

.noticias li button:last-child:hover{
  width: 200px;
  border-radius: 15px 15px 15px 0px;
}

.noticiasInfo {
  border-bottom: 20px solid var(--mainColor2);
}


.noticiasInfo  button {
  margin: 10px;
  background-color: transparent;
  border-color:transparent;
  border-radius: 15px 15px 15px 15px;
  line-height: 0;
  color: var(--textColor2);
  font-size: x-large;
}

.noticiasInfo  p {
  position: relative;
  top: -10vh;
  line-height: 1;
  color: var(--textColor2);
  overflow: hidden;
  text-overflow: ellipsis;
  height: 95px;
  text-shadow: 1px 1px 2px black;
}

.noticiasInfo button:hover{
  /* 
   
   border-color: #FFFFFF;   
   transform: scale(1.08);
   */  

  filter:brightness(1.2);
  transition: 1s;
  cursor: pointer;

}

.noticiasInfo button img{
  width : 100%;
  height: 100%; 
  object-fit: cover;
  border-radius: 15px 15px 15px 15px;

  filter:brightness(0.8);
  color: var(--textColor);
  font: 20px "Reem Kufi Regular";
}

.noticiasInfo{
  margin-top: -30px;
  text-align: center;
  /* 
  border: black;
  border-style:solid;
  */  
  background-color: var(--mainColor2);
}

.noticiaGrande {
  width : 36vw;
  height: 29vw; 
  min-width: 300px;
  min-height: 280px;
  max-width: 500px;
  max-height: 450px;
}

.noticiaPequena1 {
  width : 19vw;
  height: 21.5vw; 
  min-width: 180px;
  min-height: 170px;
  max-width: 207px;
  max-height: 220px;
}

.noticiaPequena2 {
  width : 19vw;
  height: 21.5vw; 
  min-width: 180px;
  min-height: 170px;
  max-width: 207px;
  max-height: 220px;
}

.noticiaMedia1 {
  width : 35.9vw;
  height: 14vw;
  min-width: 300px;
  min-height: 170px;
  max-width: 450px;
  max-height: 220px;
}

.noticiaMedia2 {
  width : 35.9vw;
  height: 14vw;
  min-width: 300px;
  min-height: 170px;
  max-width: 450px;
  max-height: 220px;
}

.noticiaMedia3 {
  width : 35.9vw;
  height: 14vw;
  min-width: 300px;
  min-height: 170px;
  max-width: 450px;
  max-height: 220px;
}

.noticiaMedia4 {
  width : 35.9vw;
  height: 14vw;
  min-width: 300px;
  min-height: 170px;
  max-width: 450px;
  max-height: 220px;
}

 /*  CONTAINER DAS NOTICIAS  */ 

 @media (min-width: 1456px) {
  .noticiaPequena1 {
    position: relative;
    top: -230px;
    left: 225px;
  }

}

@media (min-width: 1491px) {
  .noticiaMedia1 {
    position: relative;
    top: -230px;
  }
  .noticiaMedia2 {
    position: relative;
    top: -240px;
    left: 960px;
  }
  .noticiasInfo{
    margin-left: -200px;
    background-color: transparent;
  }
 
}


@media (min-width: 1731px) {
  
  .noticiasInfo{
    margin-left:0vw;
    margin-right: 265px;
  }
 
}

@media (min-width: 2200px) {
  
  .noticiaMedia2 {
    position: relative;
    top: 5px;
    left: -470px;
  }
 
}


@media (max-width: 750px) {
  .videos {
    transform: scale(1.2);
    margin-top: 3%;
    border-bottom: 15px solid var(--mainColor2);
  }
}

@media (max-width: 656px) {
  
  .noticiasInfo{
    overflow-y: hidden;
    height: 32rem;
  }
 
}


@media (max-width: 400px) {
  
  .noticiasInfo{
    height: 30rem;
  }

  .noticiasInfo  button {
    font-size: 18px;
  }

  .noticiasInfo  p {
    height: 64px;
  }

  .noticiaPequena1 {
    min-width: 160px;
    min-height: 150px;
  }
  .noticiaPequena2{
    min-width: 160px;
    min-height: 150px;
  }

  .noticiaGrande {
    min-width: 280px;
    min-height: 260px;
  } 
}



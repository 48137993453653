
header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 50px;
	background-color: var(--mainColor);	
	color: var(--textColor2);
 	box-shadow: -10px 5px 5px rgb(150, 148, 148); 
		
}


.admin{
	align-items: center;
	display: flex;
	float: right;
	right: 1px;
	position: absolute;
  }

nav a {
	margin: 0 1rem;
	color: var(--textColor2);
	text-decoration: none;
}

nav a:hover {
	color: var(--textColor);
	background-color: white;
}

header .nav-btn {
	float: left;
	padding: 10px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--mainColor2);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
	
}

header > button{
	 /* 
   	margin-right: 100%;	
	*/  
	background-color: transparent;
	margin-left: -88%;	
}


header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1125px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
		width: 100%;
		display: flex;
		justify-content: flex-end;

	}

	header nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 35rem;
		width: 25%;
		min-width: 12rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.8rem;
		background-color: var(--mainColor);
		transition: 1s;
		transform: translateY(-120vh);
    	z-index: 5;
		
	}

	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 0rem;
		right: 0rem;
	}


	 /* já pega do geral
   	nav a {
		font-size: 22px;
	}
	*/ 	

  .admin{
    padding-top: 1rem;
    width: 100%;
    border-top: #eee solid 2px;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
	position: relative;
	
  }
}

/* ANTIGO INICIO*/ 	

.pesquisa {
	float: right;
	height: 50px;
	width: 30%;
	max-width: 360px;
	min-width: 200px;
	margin-top: 2px;
	/* 
	 border: 2px;
	border-color: white;
	border-style: solid;
	*/  
	box-shadow: 10px 5px 5px rgb(150, 148, 148);
}
  
.pesquisa input{
color: black;
border: white;
background-color: #F6DCC9;
opacity: 80%;
height: 50px;
width: 100%;
text-align: center;   
font-size: medium;
}
.pesquisa input:focus {
outline: none !important;
border: 2px;
border-color: white;
border-style: solid;
}
  
  
.lupa{
width: 35px;
height: 35px; 
position: relative;
top: -45px;
margin-right: 1%;
float: right;
opacity: 50%;
}

::placeholder {
color: black;
opacity: 80%;
font-size: larger;
font-weight: 100;
}
    
.logo1 {
float: right;
width: 350px;
height: 180px;
right: 10px;
position: absolute;
top: 390px;
background-color: var(--mainColor2);
border-radius: 20px 20px 20px 0px;
filter:drop-shadow(2px 2px 2px gray);

}

.logo1 img{
height: 90%;
width: 100%;
object-fit: cover;
}


  
@media (max-width:  1125px) {

.banner img{
	max-height: 400px;
}
.logo1 {
	top: 35vw;
	width: 290px;
	height: 160px;
}
}

@media (max-width: 968px) {

	.logo1 {
		top: 40vw;
		width: 270px;
		height: 140px;
	}
}

@media (max-width: 900px) {

	.logo1 {
		top: 42vw;
	}
}


@media (max-width: 830px) {
	.logo1 {
		top: 45vw;
		width: 240px;
		height: 120px;
	}
}



@media (max-width: 820px) {
	.logo1 {
		top: 41vw;
}  
}


@media (max-width: 740px) {
	.logo1 {
		top: 42vw;
	}
}

@media (max-width: 540px) {
.logo1 {
	width: 200px;
	height: 80px;
	top: 50vw;
}
}

@media (max-width: 400px) {
.logo1 {
	width: 200px;
	height: 80px;
	top: 50vw;
}
}


@keyframes animacaoImages {
	0% {
	opacity:1;
  }
  45% {
  opacity:1;
  }
  55% {
  opacity:0;
  }
  100% {
  opacity:0;
  }
}

.banner {
	/*
		z-index: -1;
		opacity: 90%;
	*/
	width: 100%;
	height: 32rem;
	position: relative;
	top: -45px;
	z-index: -1;
}
   
.banner img{
	width: 100%;
	max-height: 450px;
	object-fit: cover;

	/*
		animation-name: animacaoImages;
	*/
	animation-name: animacaoImages;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	animation-duration: 15s;
	animation-direction: alternate;
}

.banner img:nth-of-type(1) {
	animation-delay: 15s;
	position: relative;
}
  
.banner img:nth-of-type(2) {
	animation-delay: 10s;
	position: relative;
	top: -28.8rem;
}

.banner img:nth-of-type(3) {
	animation-delay: 5s;
	position: relative;
	top: -57.6rem;
}

.banner img:nth-of-type(4) {
	animation-delay: 0s;
	position: relative;
	top: -86.5rem;
}
  
@media (max-width: 1125px) {

	.banner img{
		max-height: 400px;
	}
}

/* CONTAINER LOGO e BANNER*/ 	

@media (max-width: 1125px) {

	.logo1 {
		top: 33vw;
		width: 290px;
		height: 160px;
	}

	.banner {
		height: 48vh;
	}
}

@media (max-width: 968px) {

	.logo1 {
		width: 270px;
		height: 140px;
	}
}

@media (max-width: 910px) {

	.banner {
		height: 51vw;
	}	
}


@media (max-width: 830px) {
	.logo1 {
		width: 240px;
		height: 120px;
	}
	.banner {
		height: 53vw;
	}	
}

@media (max-width: 675px) {
	.banner {
		height: 56vw;
	}	
}

@media (max-width: 615px) {
	.logo1 {
		width: 200px;
		height: 80px;
		top: 38vw;
	}
}


@media (max-width: 550px) {
	.banner {
		height: 58vw;
	}
}

@media (max-width: 415px) {
	.banner {
		height: 60vw;
	}
}	

@media (max-width: 480px) {
	.banner {
		height: 62vw;
	}
}

@media (max-width: 420px) {
	.banner {
		height: 66vw;
	}
}


/* CONTAINER IMAGEM DO BANNER (a partir da 2º)*/ 

@media (max-width: 1125px) {

	
	.banner img:nth-of-type(2) {
		top: -25.5rem;
	}
	.banner img:nth-of-type(3) {
		top: -51rem;
	}
	.banner img:nth-of-type(4) {
		top: -76.8rem;
	}
	
}

@media (max-width: 738px) {


	
	.banner img:nth-of-type(2) {
		top: calc(calc(var(--medidaHeight)) + 3vh);
	}

	.banner img:nth-of-type(3) {
		top: calc(calc(var(--medidaHeight) *2) + 7.2vh);
	}

	.banner img:nth-of-type(4) {
		top: calc(calc(var(--medidaHeight) *3) + 11.2vh);
	}
		
	.banner img {
		max-height: var(--medidaBanner)
	}
}

:root {
	--medidaHeight: calc(-57vw - 2rem);
	--medidaBanner: 56vw;
}

@media (max-width: 415px) {

	.banner img {
		max-height: 54vw;
	}
}

@media (max-width: 376px) {

	.banner img {
		max-height: 57vw;
	}
}
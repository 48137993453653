@font-face {
  font-family: "Reem Kufi Regular";
  src: url("../Geral/Reem\ Kufi\ Regular.ttf");
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "Reem Kufi Regular";
  
}

:root {
	--mainColor: #004348;
	--mainColor2: #D9D9D9;
 
  --textColor: #004348;

	--textColor2: white;
  --mainColor3: #b6a6a6;
}

body {
  font-size: 22px;
  font-weight: 200;
  overflow-x: hidden;
}

a{
  color: inherit;
}

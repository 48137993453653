
.servidores {
  height: 28rem;
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 20px;
  margin-left: 1%;
}

.servidoresCard{
  width: 200px;
  height: 300px;
  min-width: 200px;
  text-align: center;
  margin-left: 3%;
  margin-right: 0.5%;  
  background-color: var(--mainColor2);
  border: 3px var(--mainColor3);
  border-radius: 15px 15px 15px 15px;
  border-style:solid;
}


.servidoresCard p {
  position: relative;
  top: 1vh;
  color: var(--mainColor);;
  font-size: large;
}

.servidoresCard img{
  position: relative;
  width : 80%;
  height: 80%; 
  top: 10px;
  border-radius: 15px 15px 15px 15px;
  object-fit: cover;
}

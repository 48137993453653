
.local {
  text-align: left;
  max-height: 500px;
  /* 
  margin-top: 30px; 
  */   
  color: white;
  background-color: var(--mainColor);
  display: flex;
}

.localInfo {
  width: 45%;
  max-height: 45vh;
  position: relative;
  margin-top: 4%;
  margin-left: 3%; 
  margin-right: 3%; 
  margin-bottom: 3%; 

  overflow-x: auto;

}

.localInfo p:first-child{
  font-size:xx-large;
  margin-bottom: 3%; 
}

.localGaleria{
  text-align: center;
  max-width: 45%;
  /* 
  border: black;
  border-style:solid;
  */  
  margin-top: 2%;
  margin-left: 2%; 
  margin-right: 2%; 
  margin-bottom: 2%; 
  margin: auto;
}


.localGaleria img{
  width : 100%;
  height: 100%; 
  max-width: 650px;
}


.localFotos{
  width : 36vw;
  height: 29vw; 
  min-width: 300px;
  min-height: 280px;
  max-width: 500px;
  max-height: 450px;
}


/*  CONTAINER local  */ 


@media (min-width: 1491px) {

  .local{
    height: 27rem;
  }
}


@media (max-width: 850px) {
  .localInfo {
    font-size: medium;
  }  
  .localInfo p:first-child{
    font-size:x-large;
  }  
}

@media (max-width: 600px) {
  .local {
    height: 68vw;
  }
}


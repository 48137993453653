.pagina {
  /* 
  width: 98%;
  height: 120px;  
  margin-left: 1%; 
  margin-right: 1%; 
  background-color: var(--mainColor2);
  */  
  
  margin-top: 4%;
  font-size: 0;
  text-align: left;
  margin-bottom: 2%;
  
  /*   bloquear ficar página rolando */
  overflow: hidden;

}

  /*  AJUSTE 2 NOTICIAS DIREITA */ 
.infos{
  height:85vh; 
  width : 500px;
  overflow: hidden;
  margin-top: 3.2vw;

  display:flex;
  flex-direction: column;
}


.paginaInfo {

  margin-left: 8%; 
  margin-right: 10%; 
  margin-top: 1%;
  text-align: center;
  
  /*  
  background-color: var(--mainColor2);  
  border: black;
  border-style:solid; 
  */       
  display: flex;
  flex-direction: row;
}

.sobre{
  font-size: 22px;
  text-align: justify;
  
  max-width : 950px;
  min-width : 340px;
      
  margin-left: 10px;
  line-height: 1.6;
  color: var(--mainColor);
  
  
}  

.sobre:first-child{
  font-size: 32px;
}

.buttonA{

  height: 50rem;

  max-width : 400px;
  max-height: 310px; 
  min-width : 340px;
  min-height: 150px;

  background-color: var(--mainColor);    
  border-color: var(--textColor2);
  border-style: none;
  border-radius: 15px 15px 0px 0px;
  cursor: default;
  
  
}

.buttonB{

  max-width : 400px;  
  min-width : 340px;

  max-height: 70px; 
  
  background-color: var(--mainColor);    
  border-color: var(--textColor2);
  border-style: none;
  cursor: text;

  display: flex;  
}

.buttonB:last-child{
  border-radius: 0px 0px 15px 15px;
  height: 82px; 
  max-height: 200px; 
  text-align: left;
}

.buttonB:last-child span{
  margin-top: 5px;
}

span{
  font-size: 20px;
  margin-top: 15px;
  margin-left: 5px; 
  color: var(--textColor2);
}

.icones{
	background: transparent;
	border: transparent;
	outline: transparent;
	color: var(--mainColor2);
	font-size: 2.4rem;

  margin-left: 10px;
  margin-top: 10px;
  cursor: default;
}

.paginaInfo img{
  
  width : 90%;
  max-height: 180px;
  margin-top: -4%;
  object-fit: cover;
  
  background-color: var(--textColor2);
  border-color: var(--textColor2);
  color: var(--textColor);
  border-style: none;
  border-radius: 15px 15px 15px 15px;
   /*  AJUSTE MANUAL DAS FOTOS  */ 
  object-position: 0 20%;
}

.infos  p {
  position: relative;

  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  text-align:center;

  white-space: break-spaces;

  width: 95%;
  height: 40px;
  left: 10px;
  margin-left: 0vw;  
  top: 1.5vh;

  line-height: 1;
  color: var(--textColor2);
  text-shadow: 1px 1px 2px black;
}

@media (max-width: 1290px) {
  .paginaInfo {  
    flex-direction: column;
  }
  .infos{
    height: 50rem; 
    max-width: 380px;
    margin: auto;
  }
}


@media (max-width: 499px) {
  .sobre {
    font-size: 20px;
    min-width : 300px;
  }
  .infos  p {
    font-size: 20px;
    height: 38px; 
  }
  .infos{
    margin-left: -15px;
  }
}

@media (max-width: 399px) {
  .infos{
    margin-left: -25px;
  }
}

@media (max-width: 376px) {
  .infos{
    width: 360px;
  }
}

@media (max-width: 360px) {
  .infos{
    width: 350px;
  }
}







.intermediario{
  margin-top: 5vh;
  text-align: center;
}


.intermediario button{
  width: 170px;
  height: 70px; 

  font-size: 25px;
  
  background-color: var(--mainColor);
  border-radius: 15px 15px 15px 15px;
  border-color: var(--mainColor);
  border-style: none;
  color: var(--textColor2);
  
  margin-bottom: 1rem;
  margin-left: 2%; 
  margin-right: 2%;   
}

.intermediario button:hover{
  background-color: var(--textColor2);
  color: var(--mainColor);
  border-color: var(--textColor);
  border-style:solid;
  
}